import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { HomeDto, GalleriesDto, Dream } from 'src/app/models/HomeDto';
import { Image, Action, ImageModalEvent, Description, PlainGalleryConfig, PlainGalleryStrategy, AdvancedLayout } from '@ks89/angular-modal-gallery';
import { GeneralInfo } from 'src/app/models/GeneralInfo';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FulfilComponent } from '../fulfil/fulfil.component';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DataService]
})
export class HomeComponent implements OnInit {
  DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto'
  };

  data: HomeDto;
  imageUrlArray: Array<any> = [];
  galleries: Array<GalleriesDto> = [];
  gal: Array<Image> = [];
  imagesArray: Array<Image[]> = [];
  customPlainGalleryRowConfigAll: PlainGalleryConfig[] = [];
  generalInfo: GeneralInfo;
  dreamDetails: Dream;
  percents: number = 0;
  intPercents: number = 0;
  deliveredPercents: number = 0;
  deliveredIntPercents: number = 0;
  totalDreams: number = 0;

  constructor(
    public dataService: DataService,
    public dialog: MatDialog
  ) {
    this.dataService.getHome().subscribe((data: HomeDto) => {
      this.data = data;
      this.percents = 100 - (data.dreams.totalFulfilled / data.dreams.total * 100);
      this.intPercents = data.dreams.totalFulfilled > 0 ? Math.floor(data.dreams.totalFulfilled / data.dreams.total * 100) : 0;
      this.deliveredPercents = 100 - (data.dreams.totalDelivered / data.dreams.total * 100);
      this.deliveredIntPercents = data.dreams.totalDelivered > 0 ? Math.floor(data.dreams.totalDelivered / data.dreams.total * 100) : 0;
      this.totalDreams = data.dreams.total;
      if (data.slides) {
        data.slides.forEach(slide => this.imageUrlArray.push({ url: slide.path, caption: slide.title }));
      }
    });

  }

  ngOnInit() {
    this.dataService.receiveGeneralInfo.subscribe((data: GeneralInfo) => {
      this.generalInfo = data;
    });
  }

  convertStringToNumber(value: number): number {
    return +value;
  }

  openDialog(dreamId: number): void {
    this.dataService.getDream(dreamId)
      .subscribe(
        (data: Dream) => {
          this.dreamDetails = data
          data.status = "PENDING"
          let dialogRef = this.dialog.open(FulfilComponent, {
            width: '80%',
            height: '75%',
            data: data
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result == 'SUCCESSFULLY_SAVED') {
              this.dataService.getHome().subscribe((data: HomeDto) => {
                this.data = data;
                this.percents = 100 - (data.dreams.totalFulfilled / (data.dreams.totalPending + data.dreams.totalFulfilled) * 100);
                this.intPercents = Math.floor(data.dreams.totalFulfilled / (data.dreams.totalPending + data.dreams.totalFulfilled) * 100);
              });
            }
          });
        },
        error => {
          console.log(error);
        });
  }
}
