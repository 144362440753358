import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GeneralInfo, About } from 'src/app/models/GeneralInfo';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  private dataService: DataService;
  showGeneralInfo: Observable<GeneralInfo>;
  generalInfo: About;
  constructor(dataService: DataService,
    translate: TranslateService) {
    this.dataService = dataService;
      translate.setDefaultLang(this.dataService.locale);
      translate.use(this.dataService.locale);
   }

  ngOnInit() {
    this.showGeneralInfo = this.dataService.receiveGeneralInfo;
    this.dataService.receiveGeneralInfo.subscribe((data: GeneralInfo) => {
      this.generalInfo = data.about;
    });
  }

}
