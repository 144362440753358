import { Component, OnInit } from '@angular/core';
import { News } from 'src/app/models/HomeDto';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {

  news: News;
  newsId: number;
  constructor(
    public dataService: DataService,
    private route: ActivatedRoute
  ) { 
    this.route.params.subscribe(res => this.newsId = res.newsId)}

  ngOnInit() {
    this.dataService.getNewsDetails(this.newsId)
    .subscribe(
      (data: News) => {
        this.news = data;
      },
      error => {
        
      }
    )
  }

}
