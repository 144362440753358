import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { GeneralInfo } from 'src/app/models/GeneralInfo';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  providers: [DataService]
})
export class ContactComponent implements OnInit {
  @ViewChild('resetClick', {static: false}) resetClick: ElementRef<HTMLElement>;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  phoneFormControl = new FormControl('', [
    Validators.required
  ]);
  nameFormControl = new FormControl('', [
    Validators.required
  ]);
  
  matcher = new MyErrorStateMatcher();
  name: any;
  phone: any;
  email: any;
  message: string = '';
  generalInfo: GeneralInfo;
  spinner: NgxSpinnerService;

  constructor(
    public dataService: DataService,
    public alertService: AlertService,
    public translate: TranslateService,
    spinnerService: NgxSpinnerService
  ) {
    
    this.spinner = spinnerService;
   }

  ngOnInit() {
    this.dataService.receiveGeneralInfo.subscribe((data: GeneralInfo) => {
      this.generalInfo = data;
      if (!this.generalInfo) {
        
      }
      console.log(this.generalInfo);
    });
  }

  convertStringToNumber(value: number): number {
    return +value;
  }

  sendEmail() {
    if (typeof(this.name) == 'undefined' || 
        typeof(this.email) == 'undefined' || typeof(this.phone) == 'undefined') {
          this.alertService.warning('Please complete all required fields !'); 
          return false;
      }
      this.spinner.show();
      let finalArr = {
        name: this.name, phone: this.phone, email: this.email, message: this.message
      };
      this.dataService.sendEmail(finalArr)
      .subscribe(
        response => {  
        this.spinner.hide();
        this.resetClick.nativeElement.click();
        this.translate.get('site.email-successfully-sent.title').subscribe((res: string) => {
          this.alertService.success(res)
        });
        },
        (error: HttpErrorResponse) => {
            this.spinner.hide();
            this.alertService.error('Something went wrong, please try again!');  
        });
  }

}
