import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { Dream, PaginatedDreams, HomeDto } from 'src/app/models/HomeDto';
import { PageEvent, MatDialogModule } from '@angular/material';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { FulfilComponent } from '../fulfil/fulfil.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-wishes',
  templateUrl: './wishes.component.html',
  styleUrls: ['./wishes.component.scss']
})
export class WishesComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // MatPaginator Inputs
  pageEvent: PageEvent;
  length = 100;
  pageSize = 10;
  datasource: Array<Dream>;
  pageIndex: number;
  status: string = 'PENDING';
  spinner: NgxSpinnerService;
  dreamDetails: Dream;
  criteria: string = "";
  gender: string = "";
  age: string = "";
  showWishes: boolean = false;

  constructor(
    public dataService: DataService,
    private router: Router,
    spinnerService: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    this.spinner = spinnerService;
  }

  get route() { return this.router.url; }

  ngOnInit() {
    if (this.router.url.endsWith('wishes.html')) {
      this.status = 'PENDING';
    }
    if (this.router.url.endsWith('fulfilled.html')) {
      this.status = 'COMPLETED';
    }
    this.getWhishes(null);
  }

  public getWhishes(event?: PageEvent) {
    this.spinner.show();
    let additionalFilters = '';
    let filterIndex = 1;
    if (this.gender == 'M' || this.gender == 'F') {
      additionalFilters += `&filter[filters][${filterIndex}][operator]=eq&filter[filters][${filterIndex}][value]=${this.gender}&filter[filters][${filterIndex}][field]=recipientSex&filter[logic]=and`;
      filterIndex++;
    }

    switch (this.age) {
      case '':
      case '0':
        break;

      case '0-3':
      case '4-6':
      case '7-9':
      case '10-12':
        const startMonth = this.age.split('-')[0];
        const endMonth = this.age.split('-')[1];
        additionalFilters += `&filter[filters][${filterIndex}][filters][0][operator]=gte&filter[filters][${filterIndex}][filters][0][value]=${startMonth}&filter[filters][${filterIndex}][filters][0][field]=recipientAge
                              &filter[filters][${filterIndex}][filters][1][operator]=lte&filter[filters][${filterIndex}][filters][1][value]=${endMonth}&filter[filters][${filterIndex}][filters][1][field]=recipientAge
                              &filter[filters][${filterIndex + 1}][operator]=eq&filter[filters][${filterIndex + 1}][value]=M&filter[filters][${filterIndex + 1}][field]=recipientAgeType&filter[logic]=and`;
        break;

      default:
        additionalFilters += `&filter[filters][${filterIndex}][operator]=eq&filter[filters][${filterIndex}][value]=${this.age}&filter[filters][${filterIndex}][field]=recipientAge
                              &filter[filters][${filterIndex + 1}][operator]=eq&filter[filters][${filterIndex + 1}][value]=Y&filter[filters][${filterIndex + 1}][field]=recipientAgeType&filter[logic]=and`;
        break;
    }

    if (event == null && this.paginator != null) {
      this.paginator.firstPage();
    }
    this.dataService.getDreams(this.status, event ? event.pageIndex : 0, event ? event.pageSize : this.pageSize, this.criteria, additionalFilters).subscribe(
      (response: PaginatedDreams) => {
        this.datasource = response.data;
        this.length = response.totalElements;
        this.showWishes = response.showWishes;
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.spinner.hide();
        }, 100);
      },
      error => {
        // handle error
      }
    );
    return event;
  }

  openDialog(dreamId: number): void {
    this.dataService.getDream(dreamId)
      .subscribe(
        (data: Dream) => {
          this.dreamDetails = data
          data.status = this.status;
          let dialogRef = this.dialog.open(FulfilComponent, {
            width: '80%',
            height: '75%',
            data: data
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result == 'SUCCESSFULLY_SAVED') {
              this.getWhishes(null);
            }
          });
        },
        error => {
          console.log(error);
        });
  }

}
