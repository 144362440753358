import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DataService } from 'src/app/services/data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dream } from 'src/app/models/HomeDto';
import { AlertService } from 'src/app/services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-fulfil',
  templateUrl: './fulfil.component.html',
  styleUrls: ['./fulfil.component.css']
})
export class FulfilComponent implements OnInit {
  private dataService: DataService;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')
  ]);
  phoneFormControl = new FormControl('', [
    Validators.required
  ]);
  firstFormControl = new FormControl('', [
    Validators.required
  ]);
  lastFormControl = new FormControl('', [
    Validators.required
  ]);

  matcher = new MyErrorStateMatcher();
  firstName: any;
  lastName: any;
  phone: any;
  email: any;
  saved: boolean = false;
  dreamId: number;
  spinner: NgxSpinnerService;

  constructor(
    dataService: DataService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<FulfilComponent>,
    @Inject(MAT_DIALOG_DATA) public dream: Dream,
    private alertService: AlertService,
    public translate: TranslateService,
    spinnerService: NgxSpinnerService) {
    this.dataService = dataService;
    this.route.params.subscribe(res => this.dreamId = res.dreamId)
    this.spinner = spinnerService;
  }

  ngOnInit() {
  }

  fulFil() {
    if (typeof (this.firstName) == 'undefined' || typeof (this.lastName) == 'undefined'
      || typeof (this.email) == 'undefined' || typeof (this.phone) == 'undefined' || this.emailFormControl.hasError('pattern')) {
      return false;
    }
    let finalArr = {
      firstName: this.firstName, lastName: this.lastName,
      phone: this.phone, email: this.email
    };
    this.spinner.show('Fulfil the dream');
    this.dataService.fulfil(this.dream.id, finalArr)
      .subscribe(
        response => {
          this.spinner.hide();
          let data: any = response;
          this.translate.get('site.successfully-fulfilled.title').subscribe((res: string) => {
            this.alertService.success(res, 15000)
          });
          this.dialogRef.close('SUCCESSFULLY_SAVED');
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          if (error.status == 404) {
            this.alertService.error('Something went wrong, please try again!');
          } else {
            this.translate.get('site.error-to-fulfil.title').subscribe((res: string) => {
              this.alertService.error(res)
            });
          }
        });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
