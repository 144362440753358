import { Injectable } from '@angular/core';
//import { HttpResponse } from "@angular/common/http";
import { TranslateLoader } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'; 
import { DataService } from './data.service';
 

@Injectable()
export class CustomTranslateLoader implements TranslateLoader  {
    
    constructor(
        protected http: HttpClient,
        protected dataService: DataService        
      ) { }  

    getTranslation(lang: string): Observable<any>{
        return Observable.create(observer => {
          this.http.get(this.dataService.getApiHost() + '/translation/'+ lang).subscribe((res: Response) => {
              let data: any = res;
                    observer.next(data);
                    observer.complete();               
                },
            error => {
                //  failed to retrieve from api, switch to local
                this.http.get("/assets/i18n/"+ lang +".json").subscribe(res => {
                    observer.next(res);
                    observer.complete();               
                })
            }
            );
        }); 
    }
}