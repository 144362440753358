import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';
import { PaginatedNews, News } from 'src/app/models/HomeDto';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  // MatPaginator Inputs
  pageEvent: PageEvent;
  length = 100;
  pageSize = 24;
  datasource: Array<News>;
  pageIndex:number;
  spinner: NgxSpinnerService;
  constructor(
    public dataService: DataService,
    spinnerService: NgxSpinnerService
  ) {
    this.spinner = spinnerService; }

  ngOnInit() {
    this.getNews(null)
  }

  public getNews(event?:PageEvent) {
    this.spinner.show();
    this.dataService.getNews(event ? event.pageIndex : 0, event ? event.pageSize : this.pageSize).subscribe(
      (response: PaginatedNews) => {
          this.datasource = response.data;
          this.length = response.totalElements;
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.spinner.hide();
        }, 100);
      },
      error =>{
        // handle error
      }
    );
    return event;
  }

}
