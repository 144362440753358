import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { GeneralInfo } from 'src/app/models/GeneralInfo';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  private dataService: DataService;
  generalInfo: GeneralInfo;
  constructor(dataService: DataService) {
    this.dataService = dataService;
  }

  ngOnInit() {
    this.dataService.receiveGeneralInfo.subscribe((data: GeneralInfo) => {
      data.about.companyAddress = `Address: ${data.about.companyAddress}`;
      this.generalInfo = data;
    });
  }

}
