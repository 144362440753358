import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class AlertService {

  constructor(public snackBar: MatSnackBar) { }

  success(message: string, duration = 2000) {
    this.snackBar.open(message, 'OK', {
      duration: duration,
      panelClass: 'alert-success',
      verticalPosition: 'top'
    });
  }

  warning(message: string, duration = 6000) {
    this.snackBar.open(message, 'OK', {
      duration: duration,
      panelClass: 'alert-warning',
      verticalPosition: 'top'
    });
  }

  error(message: string, duration = 6000) {
    this.snackBar.open(message, 'OK', {
      duration: duration,
      panelClass: 'alert-error',
      verticalPosition: 'top'
    });
  }

}