import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Event } from '../../models/HomeDto';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  event: Event;
  eventId: number;
  constructor(
    public dataService: DataService,
    private route: ActivatedRoute
  ) { 
    this.route.params.subscribe(res => this.eventId = res.eventId)}

  ngOnInit() {
    this.dataService.getEvent(this.eventId)
    .subscribe(
      (data: Event) => {
        this.event = data;
      },
      error => {
        
      }
    )
  }

}
