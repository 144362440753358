import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import 'hammerjs'; 
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CustomTranslateLoader } from './services/CustomTraslateLoader';
import { FulfilComponent } from './components/fulfil/fulfil.component';
import { AboutComponent } from './components/about/about.component';
import { FaqComponent } from './components/faq/faq.component';
import { NewsComponent } from './components/news/news.component';
import { EventsComponent } from './components/events/events.component';
import { ContactComponent } from './components/contact/contact.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { MaterialModule } from './app.material';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DataService } from './services/data.service';
import { AlertService } from './services/alert.service';
import { WishesComponent } from './components/wishes/wishes.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { EventComponent } from './components/event/event.component';
import { NewsDetailsComponent } from './components/news-details/news-details.component';
import { TruncatePipe } from './pipe/TruncatePipe';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    FulfilComponent,
    AboutComponent,
    FaqComponent,
    NewsComponent,
    EventsComponent,
    ContactComponent,
    GalleryComponent,
    WishesComponent,
    EventComponent,
    NewsDetailsComponent,
    TruncatePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    GalleryModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCypSgFiT8jKtjEjgMFF7RUAPKWsTysb_Q'
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useClass: CustomTranslateLoader
      }
    }),
    SwiperModule,
    NgxSpinnerModule
  ],
  providers: [
    AlertService,
    DataService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
