import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Faq } from 'src/app/models/HomeDto';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  items: Array<Faq>;
  constructor(
    public dataService: DataService
  ) { }

  ngOnInit() {
    this.dataService.getFaq()
    .subscribe(
      (data: Array<Faq>) => {
        this.items = data;
      }
    )
  }

}
