import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatToolbarModule, MatIconModule, MatCardModule, 
        MatMenuModule, MatInputModule, MatSelectModule, MatOptionModule,
        MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule,
        MatSnackBarModule, MatRadioModule, MatCheckboxModule, MatDialogModule,
        MatPaginatorModule
} from '@angular/material';

@NgModule({
    imports: [
        MatButtonModule, MatToolbarModule, MatIconModule, MatCardModule, 
        MatMenuModule, MatInputModule, MatSelectModule, MatOptionModule,
        MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule,
        MatSnackBarModule, MatRadioModule, MatCheckboxModule, MatDialogModule,
        MatPaginatorModule
    ],
    exports: [
        MatButtonModule, MatToolbarModule, MatIconModule, MatCardModule, 
        MatMenuModule, MatInputModule, MatSelectModule, MatOptionModule,
        MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule,
        MatSnackBarModule, MatRadioModule, MatCheckboxModule, MatDialogModule,
        MatPaginatorModule
    ]
})

export class MaterialModule {}