import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, throwError } from 'rxjs';
import { GeneralInfo } from '../models/GeneralInfo';
import { HomeDto } from '../models/HomeDto';

import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private httpClient: HttpClient;
  generalInfo = new Subject<GeneralInfo>();
  private apiHost: string = 'https://admin.childrendreams.org/api';
  // private apiHost: string = 'http://webhook.childrendreams.org/api';
  //private apiHost: string = 'http://admin.childrensdreams.code.gl/api';

  public locale: string = 'en';

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.getGeneralInfo();
  }

  getGeneralInfo() {
    this.httpClient.get(this.apiHost + '/about/general')
      .subscribe((data: GeneralInfo) => {
        this.generalInfo.next(data);
      });
  }

  get receiveGeneralInfo(): Observable<GeneralInfo> {
    return this.generalInfo.asObservable();
  }

  getHome() {
    return this.httpClient.get(this.apiHost + '/home');
  }

  getDreams(status: string, pageIndex: number, pageLenght: number, criteria: string = "", additionalFilters = "") {
    return this.httpClient
      .get(`${this.apiHost}/dreams?skip=${pageIndex * pageLenght}&top=${pageLenght}&filter[filters][0][operator]=eq&filter[filters][0][value]=${status}&filter[filters][0][field]=status${additionalFilters}&criteria=${criteria}`);
  }

  getApiHost(): string {
    return this.apiHost;
  }

  fulfil(dreamId, data) {
    return this.httpClient.post(this.apiHost + '/dreams/' + dreamId + '/fulfil', JSON.stringify(data))
      .pipe(
        catchError(this.handleError)
      );
  }

  sendEmail(data) {
    return this.httpClient.post(this.apiHost + '/send-email', JSON.stringify(data))
      .pipe(
        catchError(this.handleError)
      );
  }

  getDream(dreamId: number) {
    return this.httpClient.get(this.apiHost + '/dreams/' + dreamId);
  }

  getEvents(pageIndex: number, pageLenght: number) {
    return this.httpClient.get(this.apiHost + '/events?skip=' + pageIndex * pageLenght + '&top=' + pageLenght);
  }

  getEvent(eventId: number) {
    return this.httpClient.get(this.apiHost + '/events/' + eventId);
  }

  getFaq() {
    return this.httpClient.get(this.apiHost + '/faq');
  }

  getNews(pageIndex: number, pageLenght: number) {
    return this.httpClient.get(this.apiHost + '/news?skip=' + pageIndex * pageLenght + '&top=' + pageLenght);
  }

  getNewsDetails(newsId: number) {
    return this.httpClient.get(this.apiHost + '/news/' + newsId);
  }


  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.error);
    } else {
      console.log('Error status ' + error.message);
      if (error.status == 401 || error.status == 403) {
      }
    }
    return throwError(error.error);
  };
}


