import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { HomeDto } from 'src/app/models/HomeDto';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  private dataService: DataService;
  data: HomeDto;
  constructor(dataService: DataService) {
    this.dataService = dataService;
  }

  ngOnInit() {
    this.dataService.getHome().subscribe((data: HomeDto) => {
      this.data = data;
    });
  }

}
