import { Component, OnInit } from '@angular/core';
import { PlainGalleryConfig, Image, PlainGalleryStrategy, AdvancedLayout } from '@ks89/angular-modal-gallery';
import { DataService } from 'src/app/services/data.service';
import { HomeDto } from 'src/app/models/HomeDto';

interface Galleries {
  photos: Image[],
  config: PlainGalleryConfig,
  title: string,
  location: string
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  customPlainGalleryRowConfigAll:  PlainGalleryConfig[] = [];
  galleries: Galleries[] = [];

  constructor(
    public dataService: DataService
  ) { 

    let config = {
      strategy: PlainGalleryStrategy.CUSTOM,
      layout: new AdvancedLayout(-1, true)
    };
    let i = 0, j = 0;
    this.dataService.getHome().subscribe((data: HomeDto) => {
      if (data.events) {
        data.events.forEach(event => {
          if (event.gallery.length) {
            this.galleries[i] = {config: config, title: event.title, photos: [], location: event.location};
            for (let image of event.gallery) {
              this.galleries[i].photos.push(
                new Image(
                  j,
                  { // modal
                    img: image.url,
                    description: event.title
                  },
                  { // plain
                    img: image.url,
                    description: event.title
                  }
                )
              );
                // arr.push(new Image(
                //   image.url,
                //   null,
                //   image.alt
                // ));
                // this.imagesArray.push(new Image(
                //   image.url,
                //   null,
                //   image.alt
                // ));
                j++;
              }
            i++;
          }
        })     
      }
    });


    this.customPlainGalleryRowConfigAll[0] = {
      strategy: PlainGalleryStrategy.CUSTOM,
      layout: new AdvancedLayout(-1, true)
    }
    this.customPlainGalleryRowConfigAll[1] = {
      strategy: PlainGalleryStrategy.CUSTOM,
      layout: new AdvancedLayout(-1, true)
    }

    // this.imagesArray[0] = [new Image(
    //   0,
    //   { // modal
    //     img: 'https://raw.githubusercontent.com/Ks89/angular-modal-gallery/master/examples/systemjs/assets/images/gallery/milan-pegasus-gallery-statue.jpg',
    //     description: 'Description 1'
    //   },
    //   null
    // ),
    // new Image(
    //   1,
    //   { // modal
    //     img: 'https://raw.githubusercontent.com/Ks89/angular-modal-gallery/master/examples/systemjs/assets/images/gallery/pexels-photo-47223.jpeg'
    //   },null
    //   // { // plain
    //   //   img: 'https://raw.githubusercontent.com/Ks89/angular-modal-gallery/master/examples/systemjs/assets/images/gallery/thumbs/t-pexels-photo-47223.jpg'
    //   // }
    // ),
    // new Image(
    //   2,
    //   { // modal
    //     img: 'https://raw.githubusercontent.com/Ks89/angular-modal-gallery/master/examples/systemjs/assets/images/gallery/pexels-photo-52062.jpeg',
    //     description: 'Description 3'
    //   },
    //   { // plain
    //     img: 'https://raw.githubusercontent.com/Ks89/angular-modal-gallery/master/examples/systemjs/assets/images/gallery/thumbs/t-pexels-photo-52062.jpg',
    //     description: 'Description 3'
    //   }
    // )
    // ];
    // this.imagesArray[1] = [
    // new Image(
    //   3,
    //   { // modal
    //     img: 'https://raw.githubusercontent.com/Ks89/angular-modal-gallery/master/examples/systemjs/assets/images/gallery/pexels-photo-66943.jpeg',
    //     description: 'Description 4'
    //   },
    //   { // plain
    //     img: 'https://raw.githubusercontent.com/Ks89/angular-modal-gallery/master/examples/systemjs/assets/images/gallery/thumbs/t-pexels-photo-66943.jpg'
    //   }
    // ),
    //   new Image(
    //     4,
    //     { // modal
    //       img: 'https://raw.githubusercontent.com/Ks89/angular-modal-gallery/master/examples/systemjs/assets/images/gallery/pexels-photo-93750.jpeg'
    //     },
    //     { // plain
    //       img: 'https://raw.githubusercontent.com/Ks89/angular-modal-gallery/master/examples/systemjs/assets/images/gallery/thumbs/t-pexels-photo-93750.jpg'
    //     }
    //   )
    //   ];
    // console.log(this.imagesArray);
  }

  ngOnInit() {
  }

    
  openImageModalRow(image: Image, imgIndex: number) {
    console.log('Opening modal gallery from custom plain gallery row, with image: ', image);
    const index: number = this.getCurrentIndexCustomLayout(image, this.galleries[imgIndex].photos);
    this.galleries[imgIndex].config = Object.assign({}, this.galleries[imgIndex].config, {layout: new AdvancedLayout(index, true)});
  }
  
  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }

}
