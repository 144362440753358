import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { FulfilComponent } from './components/fulfil/fulfil.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { WishesComponent } from './components/wishes/wishes.component';
import { EventsComponent } from './components/events/events.component';
import { EventComponent } from './components/event/event.component';
import { FaqComponent } from './components/faq/faq.component';
import { NewsComponent } from './components/news/news.component';
import { NewsDetailsComponent } from './components/news-details/news-details.component';
import { GalleryComponent } from './components/gallery/gallery.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about.html',
    component: AboutComponent
  },
  {
    path: 'contact-us.html',
    component: ContactComponent
  },
  {
    path: 'fulfil/:dreamId',
    component: FulfilComponent
  },
  {
    path: 'wishes.html',
    component: WishesComponent
  },
  // { 
  //   path: 'fulfilled.html',
  //   component: WishesComponent
  // },
  {
    path: 'events.html',
    component: EventsComponent
  },
  {
    path: 'events/:eventId',
    component: EventComponent
  },
  {
    path: 'faq.html',
    component: FaqComponent
  },
  {
    path: 'news.html',
    component: NewsComponent
  },
  {
    path: 'news/:newsId',
    component: NewsDetailsComponent
  },
  // {
  //   path: 'gallery.html',
  //   component: GalleryComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
