import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { PageEvent } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginatedEvents } from 'src/app/models/HomeDto';
import { Event } from 'src/app/models/HomeDto';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  // MatPaginator Inputs
  pageEvent: PageEvent;
  length = 100;
  pageSize = 24;
  datasource: Array<Event>;
  pageIndex:number;
  spinner: NgxSpinnerService;
  constructor(
    public dataService: DataService,
    spinnerService: NgxSpinnerService
  ) {
    this.spinner = spinnerService; }

  ngOnInit() {
    this.getEvents(null)
  }

  public getEvents(event?:PageEvent) {
    this.spinner.show();
    this.dataService.getEvents(event ? event.pageIndex : 0, event ? event.pageSize : this.pageSize).subscribe(
      (response: PaginatedEvents) => {
          this.datasource = response.data;
          this.length = response.totalElements;
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.spinner.hide();
        }, 100);
      },
      error =>{
        // handle error
      }
    );
    return event;
  }

}
